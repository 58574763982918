import { CookieKeys } from '~/utils/constants.js';
import { useStore } from 'vuex';

export const state = () => ({
  devTools: true,
  globalBloks: {},
  currentStory: {
    translated_slugs: [],
  },
  storyLinks: [],
  settings: {},
  env: {},
  country: null,
  //TODO after StoryBlok migration change locale back to null
  locale: 'en',
  defaultLocale: null,
  locales: {
    pl: {
      default: 'pl',
      list: ['pl', 'en'],
    },
    ee: {
      default: 'et',
      list: ['et', 'en', 'ru'],
    },
  },
  showCountrySelect: false,
  isStoryblok: false,
  topNavHidden: false,
  dashboardNavUsed: false,
  showFeedbackWidget: false,
  feedbackWidgetModalOpen: false,
  bodyClassOverflowHidden: 0,
  leadGeneratorWidgetModalOpen: false,
});

export const mutations = {
  SET_DEVTOOLS(state, devTools) {
    state.devTools = devTools;
  },
  SET_GLOBAL_BLOKS(state, globalBloks) {
    state.globalBloks = JSON.parse(JSON.stringify(globalBloks));
  },

  SET_STORY_LINKS(state, storyLinks) {
    state.storyLinks = storyLinks;
  },

  SET_SETTINGS(state, settings) {
    state.settings = settings;
  },

  SET_ENV(state, env) {
    state.env = env;
  },

  SET_COUNTRY(state, country) {
    state.country = country;
  },

  SET_LOCALE(state, locale) {
    state.locale = locale;
  },

  SET_DEFAULT_LOCALE(state, locale) {
    state.defaultLocale = locale;
  },

  SHOW_COUNTRY_SELECT(state) {
    state.showCountrySelect = true;
  },

  SET_STORYBLOK(state) {
    state.isStoryblok = true;
  },

  SET_NAV_HIDE(state, value) {
    state.topNavHidden = value;
  },

  SET_DASHBOARD_NAV(state, value) {
    state.dashboardNavUsed = value;
  },

  SET_FEEDBACK_WIDGET(state, showFeedbackWidget) {
    state.showFeedbackWidget = showFeedbackWidget;
  },
  SET_FEEDBACK_WIDGET_MODAL(state, isOpen) {
    state.feedbackWidgetModalOpen = isOpen;
  },
  ADD_BODY_CLASS_OVERFLOW_HIDDEN(state) {
    state.bodyClassOverflowHidden = ++state.bodyClassOverflowHidden;
  },
  REMOVE_BODY_CLASS_OVERFLOW_HIDDEN(state) {
    state.bodyClassOverflowHidden =
      state.bodyClassOverflowHidden > 0 ? --state.bodyClassOverflowHidden : 0;
  },
  SET_LEAD_GENERATOR_WIDGET_MODAL(state, isOpen) {
    state.leadGeneratorWidgetModalOpen = isOpen;
  },
};

export const getters = {
  getDevTools: (state) => {
    return state.devTools;
  },

  getGlobalBloks: (state) => {
    return state.globalBloks.bloks;
  },

  getStoryLinks: (state) => {
    return state.storyLinks;
  },

  getSettings: (state) => {
    return state.settings;
  },

  getENV: (state) => {
    return state.env;
  },

  isENVIsDevelopment: (state, getters) => getters.getENV.R_ENV === 'development',

  isENVIsProduction: (state, getters) => getters.getENV.R_ENV === 'production',

  useEmulator: (state, getters) => getters.getENV.R_ENV_FIREBASE_EMULATOR === true,

  useEmulatorForCi: (state, getters) =>
    getters.getENV.R_ENV_FIREBASE_EMULATOR_CI === true,

  getCountryIsEstonia: (state) => state.country === 'ee',

  getCountryIsPoland: (state) => state.country === 'pl',

  getCountry: (state) => state.country,

  getLocale: (state) => state.locale,

  getCurrencyLabel: (state, getters) => getters.getSettings.currency,

  getTopNavHidden: (state) => {
    return state.topNavHidden;
  },

  getShowDashboardNav: (state) => {
    return state.dashboardNavUsed;
  },

  showFeedbackWidget: (state) => {
    return state.showFeedbackWidget;
  },
  feedbackWidgetModalOpen: (state) => {
    return state.feedbackWidgetModalOpen;
  },
  getBodyClassOverflowHidden: (state) => {
    return state.bodyClassOverflowHidden > 0;
  },
  leadGeneratorWidgetModalOpen: (state) => {
    return state.leadGeneratorWidgetModalOpen;
  },
  isAnyTopLevelModalOpen: (state, getters, rootState, rootGetters) => {
    return (
      getters.leadGeneratorWidgetModalOpen ||
      getters.feedbackWidgetModalOpen ||
      rootGetters['session/showSignInModal'] ||
      rootGetters['live-chat/showLiveChat']
    );
  },
};

export const actions = {
  toggleSignInModal({ commit }) {
    commit('TOGGLE_SIGNIN_MODAL');
  },

  closeSignInModal({ commit }) {
    return Promise.resolve().then(() => commit('CLOSE_SIGNIN_MODAL'));
  },

  setCountry({ commit }, country) {
    commit('SET_COUNTRY', country);
  },

  setLocale({ commit }, locale) {
    commit('SET_LOCALE', locale);
  },

  setDefaultLocale({ commit }, locale) {
    commit('SET_DEFAULT_LOCALE', locale);
  },

  showCountrySelect({ commit }) {
    commit('SHOW_COUNTRY_SELECT');
  },

  setStoryblok({ commit }) {
    commit('SET_STORYBLOK');
  },

  hideNav({ commit }, value) {
    commit('SET_NAV_HIDE', value);
  },

  dashboardNav({ commit }, value) {
    commit('SET_DASHBOARD_NAV', value);
  },

  async nuxtServerInit({ commit, state, dispatch }) {
    const config = useRuntimeConfig();

    commit('SET_ENV', {
      R_ENV: config.public.rEnv,
      R_ENV_FIREBASE_EMULATOR: config.public.useFirebaseEmulator,
      R_ENV_FIREBASE_EMULATOR_CI: config.public.rEnvFirebaseEmulatorCi,
    });

    await dispatch('getGlobalsFromStoryblok');
    await dispatch('getLinksFromStoryblok', { config });
  },

  async getGlobalsFromStoryblok({ dispatch, commit, state, getters }) {
    const config = useRuntimeConfig();
    const nuxtApp = useNuxtApp();
    const storyblokApi = useStoryblokApi();

    const locale = getters['getLocale'];
    const storyblokLocale = locale === 'en' ? 'default' : locale;

    let res;

    // globals
    try {
      const options = {
        version: config.public.STORYBLOK_VERSION,
        resolve_links: 'url',
        language: storyblokLocale,
        fallback_lang: nuxtApp.$getDefaultLocale(),
      };

      res = await storyblokApi.get(
        `cdn/stories/${nuxtApp.$getCountry() === 'pl' ? 'poland' : 'estonia'}/global`,
        options,
      );
    } catch (e) {
      dispatch('tracker/reportErrorToSentry', e, { root: true });
      console.log(e.message);
    }

    const bloks = {};

    if (res) {
      res.data.story.content.items.forEach(function (value, key) {
        bloks[value.component] = value;
      });
    }

    // modals
    try {
      const options = {
        version: config.public.STORYBLOK_VERSION,
        resolve_links: 'url',
        language: storyblokLocale,
        // fallback_lang: this.$getDefaultLocale()
      };

      res = await storyblokApi.get(`cdn/stories/modals`, options);
    } catch (e) {
      dispatch('tracker/reportErrorToSentry', e, { root: true });
    }

    if (res) {
      res.data.story.content.items.forEach(function (value, key) {
        bloks[value.component] = value;
      });
    }

    commit('SET_GLOBAL_BLOKS', {
      locale: locale,
      bloks: bloks,
    });

    commit('SET_SETTINGS', bloks.Settings);

    return true;
  },

  async getLinksFromStoryblok({ dispatch, commit }, { config }) {
    const storyblokApi = useStoryblokApi();

    let res;
    try {
      const options = {
        version: config.public.STORYBLOK_VERSION,
      };

      res = await storyblokApi.get(`cdn/links`, options);
    } catch (e) {
      dispatch('tracker/reportErrorToSentry', e, { root: true });
      console.log(e.response);
    }

    const links = res?.data?.links;

    const storyLinks = [];

    if (typeof links === 'object' && Object.keys(links)) {
      Object.keys(links).forEach(function (key) {
        const allPaths = [];

        if (!links[key].is_folder) {
          allPaths.push(`/${links[key].slug}`);

          if (links[key].alternates) {
            links[key].alternates.forEach(function (value) {
              allPaths.push(`/${value.path}`);
            });
          }

          storyLinks.push({
            name: links[key].name,
            path: links[key].slug.replace(/\/+$/, ''),
            uuid: links[key].uuid,
            id: links[key].id,
            alt_paths: allPaths,
          });
        }
      });
    }

    commit('SET_STORY_LINKS', storyLinks);

    return true;
  },

  showFeedbackWidget({ commit, dispatch, rootGetters }) {
    if (!rootGetters['live-chat/showLiveChat']) {
      commit('SET_FEEDBACK_WIDGET', true);
      dispatch('live-chat/hideLauncher');
    }
  },
  async toggleFeedbackWidgetModal({ commit, dispatch, rootGetters, getters }, isOpen) {
    if (!getters.showFeedbackWidget && isOpen) {
      await dispatch('showFeedbackWidget');
    }
    commit('SET_FEEDBACK_WIDGET_MODAL', isOpen);
  },
  showLeadGeneratorWidget({ commit, rootGetters, dispatch }) {
    const leadGeneratorAlreadyShown = this.$cookies.get(CookieKeys.LEADS_GENERATOR);

    if (
      !rootGetters.isAnyTopLevelModalOpen &&
      !rootGetters['users/hasSessionUser'] &&
      !leadGeneratorAlreadyShown
    ) {
      commit('SET_LEAD_GENERATOR_WIDGET_MODAL', true);
      // We don't want to spam user, so after closing Lead Gen we won't show it for two days
      dispatch('setLeadGeneratorWidgetShownCookie', 2);
    }
  },
  setLeadGeneratorWidgetShownCookie({ rootGetters }, lifespanInDays) {
    if (rootGetters.isENVIsProduction) {
      this.$cookies.set(CookieKeys.LEADS_GENERATOR, true, {
        path: '/',
        maxAge: 60 * 60 * 24 * lifespanInDays,
        secure: true,
        sameSite: 'None',
      });
    }
  },
  hideLeadGeneratorWidget({ commit }) {
    commit('SET_LEAD_GENERATOR_WIDGET_MODAL', false);
  },
  hideFeedbackWidget({ commit, dispatch }) {
    commit('SET_FEEDBACK_WIDGET', false);
    commit('SET_FEEDBACK_WIDGET_MODAL', false);
    dispatch('live-chat/showLauncher');
  },
  addBodyClassOverflowHidden({ commit }) {
    commit('ADD_BODY_CLASS_OVERFLOW_HIDDEN');
  },
  removeBodyClassOverflowHidden({ commit }) {
    commit('REMOVE_BODY_CLASS_OVERFLOW_HIDDEN');
  },
};
