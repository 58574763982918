const stateDefaults = {
  loaderArrayString: [],
  globalAppButtonLinksAndImages: {},
  globalDigibrokerSuccessModal: {},
};

export const state = () => ({
  loaderArrayString: stateDefaults.loaderArrayString,
  globalAppButtonLinksAndImages: stateDefaults.globalAppButtonLinksAndImages,
  globalDigibrokerSuccessModal: stateDefaults.globalDigibrokerSuccessModal,
});

export const mutations = {
  SET_GLOBAL_APP_BUTTON_LINKS_AND_IMAGES(state, globalAppButtonLinksAndImages) {
    state.globalAppButtonLinksAndImages = JSON.parse(
      JSON.stringify(globalAppButtonLinksAndImages),
    );
  },
  SET_GLOBAL_DIGIBROKER_SUCCESS_MODAL(state, globalDigibrokerSuccessModal) {
    state.globalDigibrokerSuccessModal = JSON.parse(
      JSON.stringify(globalDigibrokerSuccessModal),
    );
  },
  SET_LOADER_ARRAY_STRINGS(state, loaderArrayString) {
    state.loaderArrayString = loaderArrayString;
  },
};

export const getters = {
  getGlobalAppButtonLinksAndImages: (state) => {
    return state.globalAppButtonLinksAndImages;
  },
  getGlobalDigibrokerSuccessModal: (state) => {
    return state.globalDigibrokerSuccessModal;
  },
  getLoaderArrayString: (state) => {
    return state.loaderArrayString;
  },
};

export const actions = {
  async getGlobalAppButtonLinksAndImagesFromStoryblok(
    { dispatch, commit, state },
    locale,
  ) {
    if (!locale) {
      locale = this.$getLocale();
    }

    locale = locale === 'en' ? 'default' : locale;

    let res;

    // global/appButtonLinksAndImages
    try {
      const options = {
        version: this.$config.public.STORYBLOK_VERSION,
        resolve_links: 'url',
        language: locale,
      };

      res = await this.$storyapi.get(
        `cdn/stories/${
          this.$getCountry() === 'pl' ? 'poland' : 'estonia'
        }/global/appButtonLinksAndImages`,
        options,
      );
    } catch (e) {
      dispatch('tracker/reportErrorToSentry', e, { root: true });
      console.log(e.message);
    }

    const bloks = {};

    res.data.story.content.items.forEach(function (value) {
      bloks[value.component] = value;
    });

    commit('SET_GLOBAL_APP_BUTTON_LINKS_AND_IMAGES', {
      locale: this.$getLocale(),
      bloks: bloks,
    });

    return true;
  },

  async getGlobalDigibrokerSuccessModalFromStoryblok(
    { dispatch, commit, state },
    locale,
  ) {
    if (!locale) {
      locale = this.$getLocale();
    }

    locale = locale === 'en' ? 'default' : locale;

    let res;

    // global/digibroker-success-modal
    try {
      const options = {
        version: this.$config.public.STORYBLOK_VERSION,
        resolve_links: 'url',
        language: locale,
      };

      res = await this.$storyapi.get(
        `cdn/stories/${
          this.$getCountry() === 'pl' ? 'poland' : 'estonia'
        }/global/digibroker-success-modal`,
        options,
      );
    } catch (e) {
      dispatch('tracker/reportErrorToSentry', e, { root: true });
      console.log(e.message);
    }

    const bloks = {};

    res.data.story.content.items.forEach(function (value) {
      bloks[value.component] = value;
    });

    commit('SET_GLOBAL_DIGIBROKER_SUCCESS_MODAL', {
      locale: this.$getLocale(),
      bloks: bloks,
    });

    return true;
  },

  setLoaderArrayStringFromStoryblok({ dispatch, commit }, locale) {
    if (!locale) {
      locale = this.$getLocale();
    }
    locale = locale === 'en' ? 'default' : locale;
    try {
      const options = {
        version: this.$config.public.STORYBLOK_VERSION,
        resolve_links: 'url',
        language: locale,
      };
      this.$storyapi
        .get(
          `cdn/stories/${
            this.$getCountry() === 'pl' ? 'poland' : 'estonia'
          }/global/loader-phrases`,
          options,
        )
        .then((res) => {
          commit('SET_LOADER_ARRAY_STRINGS', res.data.story.content.loaderPhrases);
        });
    } catch (e) {
      dispatch('tracker/reportErrorToSentry', e, { root: true });
      console.log(e.message);
    }

    return true;
  },
};
