<template>
  <div
    ref="rich-text-content"
    :class="plain ? '' : 'r-rich-text'"
    v-html="content"
  ></div>
</template>

<script>
export default {
  name: 'SnippetRichText',
  props: {
    blok: {
      type: Object,
      required: true,
    },
    /* When enabled, does not include any Rich Text styles for custom formatting */
    plain: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['richTextLinkClick'],
  computed: {
    content() {
      return localizedRenderRichText(this.blok, this.$localizedPath);
    },
  },
  mounted() {
    const links = this.$refs['rich-text-content']?.getElementsByTagName('a');
    if (Array.isArray(links)) {
      links.forEach((link) => {
        link.addEventListener('click', this.onRichTextLinkClick);
      });
    }
  },

  updated() {
    this.$nextTick(() => {
      const links = this.$refs['rich-text-content'].getElementsByTagName('a');
      links.forEach((link) => {
        link.addEventListener('click', this.onRichTextLinkClick);
      });
    });
  },

  methods: {
    onRichTextLinkClick() {
      this.$emit('richTextLinkClick');
    },
  },
};
</script>

<style scoped>
a {
  @apply transition duration-200 ease-in-out text-rendin-500 hover:text-rendin-300 hover:underline focus:underline font-medium text-base cursor-pointer;
}

p {
  @apply my-4;
}
</style>
