import { connectFirestoreEmulator } from '@firebase/firestore';
import {
  browserLocalPersistence,
  connectAuthEmulator,
  getAuth,
  setPersistence,
} from '@firebase/auth';
import { type FirebaseOptions, initializeApp } from '@firebase/app';
import { initializeFirestore } from 'firebase/firestore';
import { defineNuxtPlugin, useRuntimeConfig } from '#app';
import { useStore } from 'vuex';

export default defineNuxtPlugin({
  name: 'firebase',
  dependsOn: ['tracker'],
  async setup() {
    const config = useRuntimeConfig();

    const firebaseConfig: FirebaseOptions = {
      apiKey: config.public.useFirebaseEmulator
        ? 'this-does-not-matter-but-it-does-not-work-without-it'
        : config.public.firebaseApiKey,
      authDomain: config.public.useFirebaseEmulator
        ? config.public.firebaseAuthDomain
        : config.public.firebaseAuthDomain,
      projectId: config.public.useFirebaseEmulator
        ? config.public.rEnvFirebaseEmulatorCi
          ? 'rendin-ci'
          : 'rendin-local'
        : config.public.firebaseProjectId,
      storageBucket: config.public.useFirebaseEmulator
        ? ''
        : config.public.firebaseStorageBucket,
      messagingSenderId: config.public.useFirebaseEmulator
        ? ''
        : config.public.firebaseMessagingSenderId,
      appId: config.public.useFirebaseEmulator ? '' : config.public.firebaseAppId,
      measurementId: config.public.useFirebaseEmulator
        ? ''
        : config.public.firebaseMeasurementId,
    };

    const app = initializeApp(firebaseConfig);

    const auth = getAuth(app);

    const firestoreSettings = config.public.useFirebaseEmulator
      ? { experimentalForceLongPolling: !!config.public.rEnvFirebaseEmulator }
      : {};
    const firestore = initializeFirestore(app, firestoreSettings);

    // Set persistence for auth (if needed)
    setPersistence(auth, browserLocalPersistence);

    // Connect to emulators if applicable
    if (config.public.useFirebaseEmulator) {
      const EMULATOR_PORT = 9099;
      const EMULATOR_HOST = 'http://localhost';

      connectAuthEmulator(
        auth,
        `${config.public.emulatorHost}:${config.public.emulatorPort}`,
      );
      connectFirestoreEmulator(firestore, EMULATOR_HOST, EMULATOR_PORT);
    }

    const store = useStore();
    // Setup auth listeners, which will relay information to users store
    auth.onAuthStateChanged(async (next) => {
      const claims = next ? (await next.getIdTokenResult(true)).claims : null;

      store.dispatch('users/onAuthStateChangedAction', {
        authUser: next,
        claims: claims,
      });
      store.commit('users/ON_AUTH_STATE_CHANGED_MUTATION', {
        authUser: next,
        claims: claims,
      });
    });

    return {
      provide: {
        auth: auth,
        firestore: firestore,
      },
    };
  },
});
