import { collection, limit, onSnapshot, orderBy, query } from 'firebase/firestore';
import { debounce } from 'lodash';
import {
  actionFirebaseRequest,
  setupGetters,
  setupMutations,
  setupState,
} from '~/store/firebase';

// ----------------------------------------------
// -------------- DEFINE REQUESTS ---------------
// --------- implement these in actions ---------
const requestGetProfileNotifications = 'getProfileNotifications';
const requestPutProfileNotifications = 'putProfileNotifications';
const NOTIFICATIONS_GET_LIMIT = 100;

const requests = [requestGetProfileNotifications, requestPutProfileNotifications];

const stateDefaults = {
  notifications: [],
  pagination: {
    nextStartAtId: null,
    currentPageResultsCount: null,
  },
  fetchNotificationsInProgress: false,
  putNotificationsInProgress: false,
  localizationBlok: null,
  fetchLocalizationsInProgress: false,
  fetchNotificationsInitialLoadDone: false,
  dbListener: null,
};

export const state = () => ({
  /**
   * @type {Notification[]}
   */
  notifications: stateDefaults.notifications,
  pagination: { ...stateDefaults.pagination },
  fetchNotificationsInProgress: stateDefaults.fetchNotificationsInProgress,
  putNotificationsInProgress: stateDefaults.putNotificationsInProgress,
  fetchNotificationsInitialLoadDone: stateDefaults.fetchNotificationsInitialLoadDone,
  localizationBlok: stateDefaults.localizationBlok,
  fetchLocalizationsInProgress: state.fetchLocalizationsInProgress,
  dbListener: stateDefaults.dbListener,
  ...setupState(requests),
});

export const getters = {
  notifications: (state) => state.notifications,
  localizationBlok: (state) => state.localizationBlok,
  pagination: (state) => {
    return state.pagination;
  },
  initialLoadRequired: (state) => !state.fetchNotificationsInitialLoadDone,
  fetchNotificationsInProgress: (state) => state.fetchNotificationsInProgress,
  fetchLocalizationsInProgress: (state) => state.fetchLocalizationsInProgress,
  putNotificationsInProgress: (state) => state.putNotificationsInProgress,
  localizationsLoadRequired: (state) =>
    state.localizationBlok === null && !state.fetchLocalizationsInProgress,
  uninteractedNotifications: (state) => {
    return state.notifications.filter(
      (notification) => !notification.data.notification.firstInteractedTime,
    );
  },
  dbListener: (state) => state.dbListener,
  totalNotificationsCount: (state) => {
    return state.notifications.length;
  },
  hasFetchedAllAvailableNotifications: (state, getters) => {
    return state.fetchNotificationsInitialLoadDone && !getters.pagination.nextStartAtId;
  },
  ...setupGetters(requests),
};

/**
 * @typedef {{
 *  success: boolean,
 *  request: NotificationsResponseRequest,
 *  data: Notification[],
 *  error?: {
 *    code: string,
 *    message: string,
 *  },
 *  pagination?: {
 *    nextStartAtId?: string,
 *    currentPageResultsCount: number,
 *  },
 * }} NotificationsGetResponse
 */

/**
 * @typedef {{
 *   limit?: number,
 * }} NotificationsResponseRequest
 */

/**
 * @typedef {{
 *  notificationId: string,
 *  created: string,
 *  data: NotificationBody,
 *  notificationType: NotificationType,
 * }} Notification
 */

/**
 * @typedef {{
 *  address?: string,
 *  candidateId: string,
 *  agreementId?: string,
 *  advertisementId?: string,
 *  notification: {
 *    firstInteractedTime?: string,
 *  }
 * }} NotificationBody
 */

/**
 * @typedef {'NEW_CANDIDATE'} NotificationType
 */

export const mutations = {
  SET_DB_LISTENER: function (state, listener) {
    state.dbListener = listener;
  },
  SET_NOTIFICATIONS: function (state, notifications) {
    state.notifications = notifications;
  },
  MARK_NOTIFICATION_AS_INTERACTED: function (state, notificationId) {
    const notification = state.notifications.find(
      (notification) => notification.notificationId === notificationId,
    );

    if (notification) {
      const firstInteractedTime = new Date().toISOString();
      notification.data.notification.firstInteractedTime = firstInteractedTime;
    }
  },
  PUSH_NOTIFICATIONS_AT_START: function (state, notifications) {
    state.notifications.unshift(...notifications);
  },
  PUSH_NOTIFICATIONS: function (state, notifications) {
    state.notifications.push(...notifications);
  },
  SET_PAGINATION: function (state, pagination) {
    state.pagination = pagination;
  },
  SET_FETCH_NOTIFICATIONS_IN_PROGRESS: function (state, fetchNotificationsInProgress) {
    state.fetchNotificationsInProgress = fetchNotificationsInProgress;
  },
  SET_PUT_NOTIFICATIONS_IN_PROGRESS: function (state, putNotificationsInProgress) {
    state.putNotificationsInProgress = putNotificationsInProgress;
  },
  SET_FETCH_NOTIFICATIONS_INITIAL_LOAD_DONE: function (
    state,
    fetchNotificationsInitialLoadDone,
  ) {
    state.fetchNotificationsInitialLoadDone = fetchNotificationsInitialLoadDone;
  },
  SET_LOCALIZATION_BLOK: function (state, localizationBlok) {
    state.localizationBlok = localizationBlok;
  },
  SET_FETCH_LOCALIZATIONS_IN_PROGRESS: function (state, fetchLocalizationsInProgress) {
    state.fetchLocalizationsInProgress = fetchLocalizationsInProgress;
  },
  RESET: function (state) {
    state.notifications = [];
    state.pagination = { ...stateDefaults.pagination };
    state.fetchNotificationsInProgress = stateDefaults.fetchNotificationsInProgress;
    state.putNotificationsInProgress = stateDefaults.putNotificationsInProgress;
    state.fetchNotificationsInitialLoadDone =
      stateDefaults.fetchNotificationsInitialLoadDone;
    state.localizationBlok = stateDefaults.localizationBlok;
    state.fetchLocalizationsInProgress = stateDefaults.fetchLocalizationsInProgress;

    /* Remove listener if in use */
    if (state.dbListener) state.dbListener();
    state.dbListener = stateDefaults.dbListener;
  },
  ...setupMutations(requests),
};

export const actions = {
  firebaseRequest: actionFirebaseRequest(),
  init({ commit, dispatch, getters, rootGetters }) {
    /* This is client-only feature */
    if (!import.meta.client) return;

    if (getters.localizationsLoadRequired) {
      dispatch('fetchLocalizations');
    }

    // Initialize FireStore listener to enable real-time notification updates
    if (getters.dbListener) return;

    const profile = rootGetters['users/profile'];
    if (!profile?.id) return;

    const db = this.$fire.firestore;

    const q = query(
      collection(db, 'profiles', profile.id, 'profiles_notifications'),
      orderBy('created', 'desc'),
      limit(1),
    );

    /* Debounce in case we have an array of updates in very short amount of time */
    const debouncedFetch = debounce(() => {
      dispatch('fetchNewNotifications');
    }, 1000);

    const listenerHandle = onSnapshot(q, () => {
      debouncedFetch();
    });

    commit('SET_DB_LISTENER', listenerHandle);
  },
  reset({ commit }) {
    commit('RESET');
  },
  fetchNewNotifications({ commit, dispatch, getters }) {
    commit('SET_FETCH_NOTIFICATIONS_IN_PROGRESS', true);
    return Promise.resolve()
      .then(() =>
        dispatch('firebaseRequest', {
          request: 'getProfileNotifications',
          data: {
            request: {
              pageStartAtId: undefined,
              limit: NOTIFICATIONS_GET_LIMIT,
            },
          },
        }),
      )
      .then(({ response }) => {
        if (!response.success) {
          throw response.error;
        }

        let newNotifications;
        if (getters.notifications.length > 0) {
          const previousNewestNotificationDate = new Date(
            getters.notifications[0].created,
          );
          newNotifications = response.data.filter(
            (notification) =>
              new Date(notification.created) > previousNewestNotificationDate,
          );
        } else {
          newNotifications = response.data;
        }

        commit('PUSH_NOTIFICATIONS_AT_START', newNotifications);

        if (getters.initialLoadRequired) {
          commit('SET_PAGINATION', response.pagination);
          commit('SET_FETCH_NOTIFICATIONS_INITIAL_LOAD_DONE', true);
        }
      })
      .catch((err) => {
        dispatch('tracker/reportErrorToSentry', err, { root: true });
      })
      .finally(() => {
        commit('SET_FETCH_NOTIFICATIONS_IN_PROGRESS', false);
      });
  },
  fetchNotifications({ commit, dispatch, getters }) {
    if (
      getters.hasFetchedAllAvailableNotifications ||
      getters.fetchNotificationsInProgress ||
      getters.initialLoadRequired
    ) {
      return Promise.resolve();
    }

    commit('SET_FETCH_NOTIFICATIONS_IN_PROGRESS', true);
    return Promise.resolve()
      .then(() =>
        dispatch('firebaseRequest', {
          request: 'getProfileNotifications',
          data: {
            request: {
              pageStartAtId: getters.pagination.nextStartAtId || undefined,
              limit: NOTIFICATIONS_GET_LIMIT,
            },
          },
        }),
      )
      .then(({ response }) => {
        if (!response.success) {
          throw response.error;
        }

        commit('PUSH_NOTIFICATIONS', response.data);
        commit('SET_PAGINATION', response.pagination);
      })
      .catch((err) => {
        dispatch('tracker/reportErrorToSentry', err, { root: true });
      })
      .finally(() => {
        commit('SET_FETCH_NOTIFICATIONS_IN_PROGRESS', false);
      });
  },
  fetchLocalizations({ commit, dispatch, getters, rootGetters }) {
    if (getters.fetchLocalizationsInProgress) {
      return Promise.resolve();
    }

    commit('SET_FETCH_LOCALIZATIONS_IN_PROGRESS', true);
    return Promise.resolve()
      .then(() => {
        const options = {
          version: this.$config.public.STORYBLOCK_VERSION,
          resolve_links: 'url',
          language: rootGetters.getLocale,
        };

        const countrySlug = this.$getCountry() === 'pl' ? 'poland' : 'estonia';

        const path = `cdn/stories/${countrySlug}/global/activity-hub-notifications`;

        return this.$storyapi.get(path, options);
      })
      .then((response) => {
        commit('SET_LOCALIZATION_BLOK', response.data.story.content);
      })
      .catch((error) => {
        dispatch('tracker/reportErrorToSentry', error, { root: true });
      })
      .finally(() => {
        commit('SET_FETCH_LOCALIZATIONS_IN_PROGRESS', false);
      });
  },
  putProfileNotifications({ commit, dispatch }, { actions }) {
    commit('SET_PUT_NOTIFICATIONS_IN_PROGRESS', true);

    return Promise.resolve()
      .then(() =>
        dispatch('firebaseRequest', {
          request: 'putProfileNotifications',
          data: {
            request: {
              actions: actions,
            },
          },
        }),
      )
      .then(({ response }) => {
        if (!response.success) {
          throw response.error;
        }

        return response;
      })
      .catch((err) => {
        dispatch('tracker/reportErrorToSentry', err, { root: true });
      })
      .finally(() => {
        commit('SET_PUT_NOTIFICATIONS_IN_PROGRESS', false);
      });
  },
  markNotificationsAsInteracted({ commit, dispatch }, { notificationIds }) {
    const actions = notificationIds.map((notificationId) => {
      return {
        id: notificationId,
        action: 'mark_as_interacted',
      };
    });

    /* We assume that the request will succeed and set all as interacted preemptivelly for better UX */
    notificationIds.forEach((notificationId) => {
      commit('MARK_NOTIFICATION_AS_INTERACTED', notificationId);
    });

    return Promise.resolve()
      .then(() =>
        dispatch('putProfileNotifications', {
          actions: actions,
        }),
      )
      .catch((err) => {
        dispatch('tracker/reportErrorToSentry', err, { root: true });
      });
  },
};
