<template>
  <div
    class="w-full bg-white border border-gray-300 flex items-center"
    style="height: 45px; border-radius: 0.5rem"
  >
    <div style="padding: 13px; border-radius: 0.5rem 0 0 0.5rem">
      <div
        class="animate-pulse bg-gray-300"
        style="width: 20px; height: 13px; margin-left: 5px; margin-right: 5px"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PhoneInputPlaceholder',
};
</script>
