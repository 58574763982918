const stateDefaults = {
  routerHistory: [],
  backButtonClicked: false,
  dirtyRouterHistory: [],
};

export const state = () => ({
  routerHistory: stateDefaults.routerHistory,
  backButtonClicked: stateDefaults.backButtonClicked,
  dirtyRouterHistory: stateDefaults.dirtyRouterHistory,
});

export const mutations = {
  ADD_PREVIOUS_PAGE_URL: function (state, previousPageUrl) {
    state.routerHistory.push(previousPageUrl);
    state.dirtyRouterHistory.push(previousPageUrl);
  },
  DELETE_PREVIOUS_PAGE_URL: function (state) {
    state.routerHistory.pop();
  },
  BACK_BUTTON_IS_PRESSED: function (state) {
    state.backButtonClicked = true;
  },
  BACK_BUTTON_IS_UNPRESSED: function (state) {
    state.backButtonClicked = false;
  },
};

export const getters = {
  getRouterHistory: (state) => state.routerHistory,
  getDirtyRouterHistory: (state) => state.dirtyRouterHistory,
  getPreviousDirtyPagePath: (state) => {
    if (state.dirtyRouterHistory.length > 0) {
      return state.dirtyRouterHistory[state.dirtyRouterHistory.length - 1];
    } else {
      return null;
    }
  },
  getPreviousPagePath: (state) => {
    if (state.routerHistory.length > 0) {
      return state.routerHistory[state.routerHistory.length - 1];
    } else {
      return null;
    }
  },
  isBackButtonClicked: (state) => state.backButtonClicked,
};

export const actions = {
  addPageUrl({ commit }, pageUrl) {
    commit('ADD_PREVIOUS_PAGE_URL', pageUrl);
  },

  deletePreviousPageUrl({ commit }) {
    commit('DELETE_PREVIOUS_PAGE_URL');
  },

  goToPreviousPage({ commit, state }, fallbackUrl) {
    commit('BACK_BUTTON_IS_PRESSED');
    if (state.routerHistory.length > 0) {
      this.$router.push(`${this.$localizedPath(getters.getPreviousPagePath(state))}`);
    } else {
      this.$router.push(`${fallbackUrl}`);
    }
    commit('DELETE_PREVIOUS_PAGE_URL');
  },

  unpressBackButton({ commit }) {
    commit('BACK_BUTTON_IS_UNPRESSED');
  },

  pressBackButton({ commit }) {
    commit('BACK_BUTTON_IS_PRESSED');
  },
};
