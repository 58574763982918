import { useStore } from 'vuex';
import type { VueI18n } from 'vue-i18n';

export default defineNuxtPlugin({
  name: 'i18n',
  async setup(nuxtApp) {
    const store = useStore();

    // Bit ugly, but this should always exist on nuxtApp, same issue found here: https://github.com/nuxt-modules/i18n/issues/2955
    // Perhaps we should just not use store for that anymore
    const i18n = nuxtApp.$i18n as VueI18n;

    await store.dispatch('setLocale', i18n.locale);
    nuxtApp.hook('i18n:localeSwitched', async ({ newLocale }) => {
      await store.dispatch('setLocale', newLocale);
    });

    const getLocale = () => {
      return i18n.locale;
    };

    return {
      provide: {
        getLocale,
      },
    };
  },
});
