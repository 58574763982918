import { default as _91_46_46_46page_93TnZK5Y8EGAMeta } from "/workspace/pages/[...page].vue?macro=true";
import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as r_45interface_45componentsWvBLFR7TqOMeta } from "/workspace/pages/r-interface-components.vue?macro=true";
export default [
  {
    name: "page",
    path: "/:page(.*)*",
    component: () => import("/workspace/pages/[...page].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "r-interface-components",
    path: "/r-interface-components",
    component: () => import("/workspace/pages/r-interface-components.vue").then(m => m.default || m)
  }
]