<template>
  <div
    v-editable="blok"
    class="mb-4"
  >
    <r-accordion-group
      :active-index="activeIndex"
      :flavor="blok.flavor"
      :stay-open="blok.stayOpen"
      :value="accordionValue"
      @switch="(index) => activeIndex = index"
    >
      <div
        v-for="accordion in blok.accordion"
        :id="accordion.accordionId"
        :key="accordion._uid"
        :slot="accordion._uid"
      >
        <RichText
          :blok="accordion.content"
          class="-my-4"
        />
      </div>
    </r-accordion-group>
  </div>
</template>

<script>
export default {
  name: 'AccordionGroup',
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    activeIndex: -1,
  }),
  computed: {
    accordionValue() {
      const accordions = [];

      this.blok.accordion.forEach((el) => {
        accordions.push({
          'title': el.title,
          'id': el._uid,
          'accordionId': el.accordionId,
          'open': el.open,
        });

      });

      return accordions;
    },
  },
  mounted() {
    this.blok.accordion.forEach((element, i) => {
      if (element.open) this.activeIndex = i;
    });
  },
};
</script>
