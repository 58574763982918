import { defineNuxtPlugin } from '#app/nuxt'
import { LazySectionBanner, LazySectionBannerColumn, LazyAbTest, LazyAccordion, LazyAccordionGroup, LazyPhoneInputPlaceholder, LazyRichText, LazyRichTextBlok, LazyPage } from '#components'
const lazyGlobalComponents = [
  ["SectionBanner", LazySectionBanner],
["SectionBannerColumn", LazySectionBannerColumn],
["AbTest", LazyAbTest],
["Accordion", LazyAccordion],
["AccordionGroup", LazyAccordionGroup],
["PhoneInputPlaceholder", LazyPhoneInputPlaceholder],
["RichText", LazyRichText],
["RichTextBlok", LazyRichTextBlok],
["Page", LazyPage],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
