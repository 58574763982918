declare global {
  interface Window {
    dataLayer: undefined | unknown[];
    gtag: undefined | object;
  }
}

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  window.dataLayer = window.dataLayer || [];

  function gtag(...args: unknown[]) {
    window.dataLayer?.push(args);
  }

  gtag('js', new Date());

  gtag('config', config.public.GOOGLE_ANALYTICS_ID, {
    send_page_view: true,
  });
  gtag('config', config.public.GOOGLE_UNIVERSAL_ANALYTICS_ID, {
    send_page_view: true,
  });
  gtag('config', config.public.GOOGLE_ADS_ID, {
    send_page_view: true,
  });

  window.gtag = gtag;

  const script = document.createElement('script');
  script.async = true;
  script.id = 'gtag';
  script.src = `//www.googletagmanager.com/gtag/js`;
  document.head.appendChild(script);

  return {
    provide: {
      gtag: gtag,
    },
  };
});
