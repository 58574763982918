import { defineNuxtPlugin } from '#app';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('lazy-background', {
    mounted(el: HTMLElement, binding: any) {
      const imageUrl: string = binding.value;

      // Create an IntersectionObserver instance
      const observer = new IntersectionObserver(
        (entries: IntersectionObserverEntry[]) => {
          entries.forEach((entry: IntersectionObserverEntry) => {
            if (entry.isIntersecting) {
              // Set the background image when element is in the viewport
              el.style.backgroundImage = `url('${imageUrl}')`;

              // Stop observing once the image has been loaded
              observer.unobserve(el);
            }
          });
        },
        {
          rootMargin: '0px',
          threshold: 0.1,
        },
      );

      // Start observing the element
      observer.observe(el);
    },
  });
});
