import { v4 as uuidv4 } from 'uuid';

import { isEmpty } from 'lodash';

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();

  const siteid = config.public.CUSTOMER_IO;
  const apikey = config.public.CUSTOMER_IO_API_KEY;
  const baseUrl =
    config.public.rEnv !== 'production'
      ? 'https://demo.analytics.rendin.co/cio'
      : 'https://analytics.rendin.co/cio';

  const cioClient = new TrackClient({
    siteid: siteid,
    apikey: apikey,
    baseUrl: baseUrl,
  });

  return {
    provide: {
      cio: cioClient,
    },
  };
});

const ANONYMOUS_ID_COOKIE = '_cioanonid';
const ID_COOKIE = '_cioid';

// Based on CustomerIO Node Library
// https://github.com/customerio/customerio-node/blob/main/lib/track.ts
class TrackClient {
  constructor({ siteid, apikey, baseUrl, defaults = {} }) {
    this.siteid = siteid;
    this.apikey = apikey;
    this.defaults = { ...defaults };

    this.trackRoot = `${baseUrl}/api/v1`;

    this.request = new CIORequest({
      apikey: apikey,
      siteid: siteid,
    });
    this.anonymousId = null;
    this.id = null;
    this._fetchOrGenerateAnonId();
    this._fetchId();
  }

  _fetchOrGenerateAnonId() {
    const cookie = getCookie(ANONYMOUS_ID_COOKIE);

    if (cookie) {
      this.anonymousId = cookie;
    } else {
      this.anonymousId = uuidv4();
    }

    setCookie(ANONYMOUS_ID_COOKIE, this.anonymousId, 365);
  }

  _fetchId() {
    const cookie = getCookie(ID_COOKIE);

    // Since cookies only support strings, we have to use special "null" comparison to not use wrong malformed id
    if (cookie) {
      if (cookie === 'null') {
        this._setId(null);
      } else {
        this._setId(cookie);
      }
    }
  }

  _setId(id) {
    this.id = id;
    setCookie(ID_COOKIE, this.id, 365);
  }

  identify(data = {}) {
    if (isEmpty(data.id)) {
      throw new MissingParamError('data.id');
    }

    this._setId(data.id);

    data.anonymous_id = this.anonymousId;

    try {
      this.request.put(
        `${this.trackRoot}/customers/${encodeURIComponent(this.id)}`,
        data,
      );
    } catch (e) {
      const errorData = {
        id: this.id,
        anonymous_id: this.anonymousId,
        data: data,
      };

      const error = new Error(`CustomerIOError: ${e} \nError Data:\n ${errorData}`);

      this.app.$sentry.captureException(error);
    }
  }

  _trackIdentified(eventName, data = {}) {
    const id = this.id;

    const payload = {
      name: eventName,
      data: data,
    };

    return this.request.post(
      `${this.trackRoot}/customers/${encodeURIComponent(id)}/events`,
      payload,
    );
  }

  _trackAnonymous(eventName, data = {}) {
    const payload = {
      name: eventName,
      anonymous_id: this.anonymousId,
      data: data,
    };

    return this.request.post(`${this.trackRoot}/events`, payload);
  }

  track(eventName, data = {}) {
    if (isEmpty(this.anonymousId) && isEmpty(this.id)) {
      throw new MissingParamError('customerId');
    }

    if (isEmpty(eventName)) {
      throw new MissingParamError('eventName');
    }

    const isIdentified = !!this.id;

    try {
      if (isIdentified) {
        this._trackIdentified(eventName, data);
      } else {
        this._trackAnonymous(eventName, data);
      }
    } catch (e) {
      const errorData = {
        eventName: eventName,
        data: data,
      };

      const error = new Error(`CustomerIOError: ${e} \nError Data:\n ${errorData}`);

      this.app.$sentry.captureException(error);
    }
  }

  trackPageView(path) {
    if (isEmpty(this.anonymousId)) {
      throw new MissingParamError('customerId');
    }

    if (isEmpty(path)) {
      throw new MissingParamError('path');
    }

    return this.request.post(
      `${this.trackRoot}/customers/${encodeURIComponent(this.anonymousId)}/events`,
      {
        type: 'page',
        name: path,
      },
    );
  }

  reset() {
    this._setId(null);
    this.anonymousId = uuidv4();
    setCookie(ANONYMOUS_ID_COOKIE, this.anonymousId, 365);
  }
}

class CIORequest {
  apikey;
  siteid;
  appKey;
  auth;

  constructor(auth) {
    if (typeof auth === 'object') {
      this.apikey = auth.apikey;
      this.siteid = auth.siteid;

      this.auth = `Basic ${btoa(`${this.siteid}:${this.apikey}`)}`;
    } else {
      this.appKey = auth;
      this.auth = `Bearer ${this.appKey}`;
    }
  }

  options(uri, method, data) {
    const body = data ? JSON.stringify(data) : null;
    const headers = {
      Authorization: this.auth,
      'Content-Type': 'application/json',
    };

    return { method, uri, headers, body };
  }

  async handler({ uri, body, method, headers }) {
    const response = await fetch(uri, {
      method: method,
      headers: headers,
      body: body,
    });

    // Track URL has been moved, we need to redirect
    if ([301, 302, 307, 308].includes(response.status)) {
      const newURI = response.headers.get('location');
      if (!newURI) {
        throw new Error(
          `Received a ${response.status} status, but no Location header was present`,
        );
      }
      return this.handler({ uri: newURI, body, method, headers });
    }

    const responseBody = await response.text();

    // Attempt to parse JSON
    let json;
    try {
      json = JSON.parse(responseBody);
    } catch (error) {
      throw new Error(
        `Unable to parse JSON. Error: ${error} \nBody:\n ${responseBody}`,
      );
    }

    // Check for successful status codes
    if (response.ok) {
      return json;
    } else {
      throw new CustomerIORequestError(
        json,
        response.statusCode || 0,
        response,
        responseBody,
      );
    }
  }

  put(uri, data = {}) {
    return this.handler(this.options(uri, 'PUT', data));
  }

  post(uri, data = {}) {
    return this.handler(this.options(uri, 'POST', data));
  }
}

class CustomerIORequestError extends Error {
  constructor(json, statusCode, response, body) {
    super(CustomerIORequestError.composeMessage(json));
    this.name = 'CustomerIORequestError';
    this.statusCode = statusCode;
    this.response = response;
    this.body = body;
  }

  static composeMessage(json) {
    if (!json) {
      return 'Unknown error';
    }

    if (json.meta && json.meta.error) {
      return json.meta.error;
    } else if (json.meta && json.meta.errors) {
      const count = json.meta.errors.length;
      return `${count} ${count === 1 ? 'error' : 'errors'}:\n${json.meta.errors
        .map((error) => `  - ${error}`)
        .join('\n')}`;
    }

    return 'Unknown error';
  }
}

class MissingParamError extends Error {
  constructor(param) {
    super(`${param} is required`);
    this.name = 'MissingParamError';
  }
}

function setCookie(name, value, daysToExpire) {
  const supportsCrossSite = false;
  const hostname = document.location.host.toLowerCase().split(':')[0];

  const getCookieExpiration = (days) => {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return date.toUTCString();
  };

  const setDocumentCookie = (domain) => {
    const expires =
      daysToExpire != null ? `;expires=${getCookieExpiration(daysToExpire)}` : '';
    const sameSitePolicy = supportsCrossSite
      ? ';SameSite=None;Secure'
      : ';SameSite=Lax;Secure';
    document.cookie = `${name}=${encodeURIComponent(
      value,
    )}${expires};path=/${sameSitePolicy}${domain ? `;domain=${domain}` : ''}`;
  };

  setDocumentCookie(hostname);
}

function getCookie(cookieName) {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}
